import Image from 'next/image';
import styles from '../ErrorCommon.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Error500 = () => {
  return (
    <div className={cx('error-screen')}>
      <Image src={'/img/icon/icon_layer_green.svg'} alt={'500Error'} width={104} height={104} />
      <h1>
        <span>페이지를</span> <span>표시할 수 없어요!</span>
      </h1>
      <p>
        서비스 이용에 불편을 드려 죄송합니다.
        <br />
        관리자에게 문의하시거나 잠시 후 다시 시도해주세요.
      </p>
    </div>
  );
};

export default Error500;
